@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Favorit';
  src: url('/creator/fonts/favorit-bold.woff2') format('woff2'),
    url('/creator/fonts/favorit-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Favorit';
  src: url('/creator/fonts/favorit-regular.woff2') format('woff2'),
    url('/creator/fonts/favorit-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tobias';
  src: url('/creator/fonts/tobias-light.woff2') format('woff2'),
    url('/creator/fonts/tobias-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* make color picker responsive */
#main-content .react-colorful,
#headlessui-portal-root .react-colorful {
  width: 100%;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.prose-theleap {
  margin: 0.5rem 0;
}

.prose-lg {
  line-height: 1.6;
}

.prose iframe {
  flex-grow: 1;
  aspect-ratio: 16 / 9;
  width: 100% !important;
  height: auto !important;
}

/* fix spacing for list */
/* fix list spacing, if list is first item and will shift rich edit */
.prose-theleap ul li > p {
  margin: 0;
}

.prose-theleap ol li > p {
  margin: 0;
}

.prose-theleap li {
  margin-top: 0;
}

.prose-theleap h1 {
  font-size: 1.6rem;
  line-height: 2rem;
}

.mobile-line {
  height: calc(100% - 1.5rem);
}

.error-page-background-image {
  background-image: url('/creator/animations/wave.gif');
  background-repeat: no-repeat;
  background-position: center bottom;
  position: fixed;
  bottom: 0;
  background-size: 100% 20%;
}

/* reduce height so the user can see the edit card */
.edit-colors .react-colorful {
  height: 150px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.gradient-outline {
  position: relative;
}
.gradient-outline > div {
  z-index: 2;
}

.gradient-outline::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(
    135deg,
    #52b448,
    #29a7ff,
    #fb4827,
    #e3c6e4,
    #ecf956,
    #52b448
  );
  background-size: 200% 200%;
  z-index: 1;
}

.gradient-overlay {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    122.47deg,
    #fb4827 0%,
    #e3c6e4 21.34%,
    #c4c1e8 38.99%,
    #29a7ff 56.25%,
    #52b448 75.66%,
    #ecf956 96.64%
  );
  opacity: 0.45;
  /* Large Shadow */
  box-shadow: 3px 6px 0px rgba(0, 0, 0, 0.1);
}

.gradient-outline-animate::after {
  animation: gradient-animation 2s ease infinite;
}

.gradient-outline-blur-sticky::before,
.gradient-outline-blur:hover::before {
  content: '';
  position: absolute;
  inset: 0px;
  background: linear-gradient(
    135deg,
    #52b448,
    #29a7ff,
    #fb4827,
    #e3c6e4,
    #ecf956,
    #52b448
  );
  filter: blur(24px);
  transform: translate3d(0px, 0px, -1px);
  border-radius: inherit;
  pointer-events: none;
}

.dashboard-ribbon {
  width: 400px;
  right: -39px;
  top: 70px;
}

.smiling-cooking {
  width: 126px;
  top: 20px;
  right: 100px;
  z-index: 2;
}

.scattered-dollar {
  width: 96px;
  top: 190px;
  right: -20px;
  z-index: 2;
}

.camera-person {
  width: 212px;
  top: 320px;
  right: 30px;
  z-index: 2;
}

.react-calendar__month-view__weekdays {
  margin-left: 1rem;
}

.react-calendar__month-view__weekdays__weekday {
  display: flex;
  justify-content: center;
  width: 71px
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__navigation__label__labelText {
  font-size: 28px;
}

.leap-gradient-border::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: linear-gradient(
    90deg,
    #52b448 0%,
    #29a7ff 21.3%,
    #fb4827 42.07%,
    #e3c6e4 60.53%,
    #ecf956 78.8%,
    #52b448 100%
  );
  border-radius: 0.25rem;
}

zapier-full-experience,
zapier-zap-manager {
  --zapier-text: #4c2116;
  --zapier-text-80: rgb(76, 33, 22 / 80%);
  --zapier-text-60: rgb(76, 33, 22 / 56%);
  --zapier-font-family: Favorit, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', Segoe UI Symbol,
    'Noto Color Emoji';
}

/* add styles to popup link button */
[data-headlessui-state='open'] .link-button {
  background-color: #e3c6e4;
}

[data-headlessui-state='open'] .info-button {
  background-color: #dcdadb;
}

.blur-background-image {
  position: relative;
}

.blur-background-image::before {
  content: '';
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url('/creator/img/onboarding-background.svg');
  background-size: auto;
  background-position: bottom -70px right -20px;
  transform: translate3d(0, 0, 0);
  filter: blur(30px);
  z-index: -1; /* Ensure the pseudo-element is behind the content */
}

@media (min-width: 768px) {
  .blur-background-image::before {
    top: 0;
    background-size: cover;
  }
}

@media (min-width: 768px) {
  .md\:signup-blur-background-image {
    position: relative;
  }
}

@media (min-width: 768px) {
  .md\:signup-blur-background-image::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url('/creator/img/signup-background.svg');
    background-size: cover;
    background-position: top;
    filter: blur(5px);
    z-index: -1; /* Ensure the pseudo-element is behind the content */
  }
}

.error-page-background-image {
  background-image: url('/creator/animations/wave.gif');
  background-repeat: no-repeat;
  background-position: center bottom;
  position: absolute;
  bottom: 0;
  background-size: 100% 20%;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}

.prose [data-node-view-content] div :first-child {
  margin-top: 0;
}
.prose [data-node-view-content] div :last-child {
  margin-bottom: 0;
}

.prose li p:has(> br.ProseMirror-trailingBreak:only-child):has(+ div.node-aiSuggestion) {
  display: none;
}
.ProseMirror:not(:focus) .persistent-selection {
  background-color: rgba(0, 119, 255, 0.3);
}

.pointer-events-all-none {
  * {
    pointer-events: none;
  }
}

.gradient-brightness {
  background: linear-gradient(90deg, black 0%, white 100%)
}

.gradient-hue {
  background: linear-gradient(
    to right,
    hsl(0, 100%, 50%),
    hsl(20, 100%, 50%),
    hsl(40, 100%, 50%),
    hsl(60, 100%, 50%),
    hsl(80, 100%, 50%),
    hsl(100, 100%, 50%),
    hsl(120, 100%, 50%),
    hsl(140, 100%, 50%),
    hsl(160, 100%, 50%),
    hsl(180, 100%, 50%),
    hsl(200, 100%, 50%),
    hsl(220, 100%, 50%),
    hsl(240, 100%, 50%),
    hsl(260, 100%, 50%),
    hsl(280, 100%, 50%),
    hsl(300, 100%, 50%),
    hsl(320, 100%, 50%),
    hsl(340, 100%, 50%),
    hsl(360, 100%, 50%)
  );
}
